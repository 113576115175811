<template>
 <v-container
    id="rewards"
    fluid
    tag="section"
  >
      <router-view></router-view>

 </v-container>

</template>

<script>

// import { CurrencyFilter } from '@/utils/filters'


export default {
  computed: {
    
  },
    components: {
      
    },
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  mounted(){
    
  },

  
}
</script>
